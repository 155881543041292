import InStockIcon from "~/components/ui/icons/in-stock-icon";
import { StockStatusIcon } from "~/components/ui/icons/stock-status-icon";
import { cn } from "~/lib/ui";

import { productTileOnStockCount } from "./product-tile-utils";

interface ProductAvailabilityInfoProps {
  availableForDelivery: boolean | undefined;
  availableForCnC: boolean | undefined;
  stockCount?: number;
  storeName?: string;
  fulfilmentMessage?: string | undefined;
}

const ProductAvailabilityInfo: React.FC<ProductAvailabilityInfoProps> = ({
  availableForDelivery,
  availableForCnC,
  stockCount,
  storeName,
  fulfilmentMessage,
}) => {
  let stockStatus: "inStock" | "lowOnStock" | "outOfStock" | "notAvailable";
  let stockText: string;

  if (!availableForCnC) {
    stockStatus = "notAvailable";
    stockText = "Not Available for C&C";
  } else if (stockCount === 0) {
    stockStatus = "outOfStock";
    stockText = `Out of stock at ${storeName}`;
  } else if (stockCount && stockCount <= productTileOnStockCount) {
    stockStatus = "lowOnStock";
    stockText = `Low stock at ${storeName}`;
  } else {
    stockStatus = "inStock";
    stockText = `In stock at ${storeName}`;
  }

  return (
    <div
      className={cn(
        "flex flex-col bg-neutral-10 px-2 py-5 text-xs font-normal",
        fulfilmentMessage ? "gap-2.5" : "gap-1",
      )}
    >
      <div className="flex sm:flex-row sm:justify-between md:flex-col md:items-start md:gap-2 xl:flex-row xl:justify-between">
        <div>
          {stockStatus === "inStock" ? (
            <>
              <InStockIcon className="mr-2 inline-block h-6 w-6" />
              {stockText}
            </>
          ) : (
            <>
              <StockStatusIcon
                className="mr-2 inline-block h-6 w-6"
                status={stockStatus}
              />
              {stockText}
            </>
          )}
        </div>
        {fulfilmentMessage && (
          <div className="flex items-center justify-center whitespace-nowrap rounded-br-lg rounded-tl-lg border-2 border-neutral-2 px-2 py-1 text-xs font-normal text-neutral-3">
            {fulfilmentMessage}
          </div>
        )}
      </div>
      <div>
        {availableForDelivery ? (
          <div>
            <InStockIcon className="mr-2 inline-block h-6 w-6" />
            Available for Delivery
          </div>
        ) : (
          <div>
            <StockStatusIcon
              className="mr-2 inline-block h-6 w-6"
              status="notAvailable"
            />
            Not available for Delivery
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductAvailabilityInfo;
