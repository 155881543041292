import type { ReactNode } from "react";

import { useCategory } from "~/contexts/mobile-menu";

import type { NavSectionContent as NavSectionContentType } from "../types";
import { LinkGroup } from "./link-group";

type Props = {
  children: ReactNode;
  content: NavSectionContentType;
};

export const NavSectionContent = ({ children, content }: Props) => {
  const topSection = content?.fields.topSection;
  const bottomSection = content?.fields.bottomSection;
  const { selectedCategory } = useCategory();
  return (
    <div className="w-full">
      {topSection && !selectedCategory && (
        <LinkGroup
          content={topSection}
          prefetch="render"
          titleClassName={"font-normal"}
        />
      )}
      {children}
      {bottomSection && !selectedCategory && (
        <LinkGroup
          content={bottomSection}
          prefetch="render"
          titleClassName={"font-normal"}
        />
      )}
    </div>
  );
};
