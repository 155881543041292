import type { ComponentProps } from "react";
import { Suspense, memo } from "react";
import { ClientOnly } from "remix-utils/client-only";

import { Await } from "@remix-run/react";

import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import { IsGiftCard } from "~/commerce-sap/shop/utils/giftcard";
import { getImageLink } from "~/commerce-sap/shop/utils/image";
import { NavigationMenuLink } from "~/components/ui/navigation-menu";
import { useURL } from "~/contexts";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";
import PriceDisplay from "~/routes/($locale)+/product-list+/components/price/price-display";
import PriceRangeDisplay from "~/routes/($locale)+/product-list+/components/price/price-range-display";
import { getLowestPrice } from "~/routes/($locale)+/product-list+/components/price/price-utils";
import { useProductUrl } from "~/routes/($locale)+/product-list+/hooks/product-url";
import type { AlgoliaSearchProductSuggestion } from "~/routes/($locale)+/resources+/search-suggestions";
import { SearchSuggestionsResource } from "~/routes/($locale)+/resources+/search-suggestions";

import "../../styles/MeganavSearchBarSuggestions.css";
import { NoImageIcon } from "../ui/icons/no-image-icon";
import { Skeleton } from "../ui/skeleton";
import { P } from "../ui/text";

const MeganavSearchBarSuggestionsSkeleton = () => {
  const skeletonsSuggestions = new Array(3)
    .fill(0)
    .map((_, i) => <Skeleton key={i} className="h-[15px] w-full" />);

  return (
    <div className="sm-max:pl-6 bg-page-background z-20 w-full rounded-lg pt-3">
      <div className="flex px-1 pb-3 sm:flex-col lg:flex-row">
        <div className="sm:w-full lg:w-1/4 lg:border-r lg:border-r-neutral-2 lg:border-opacity-10">
          <div className="p-4">{skeletonsSuggestions}</div>
        </div>
      </div>
    </div>
  );
};

export const MeganavSearchBarSuggestions = ({ q }: { q: string }) => {
  const url = useURL();

  return (
    <ClientOnly>
      {() => (
        <SearchSuggestionsResource q={q}>
          {(data, _state) => {
            return (
              <div className="MeganavSearchBarSuggestions sm-max:pl-6 bg-page-background z-20 w-full rounded-lg pt-3">
                <Suspense fallback={<MeganavSearchBarSuggestionsSkeleton />}>
                  <Await resolve={data?.suggestions}>
                    {data => {
                      if (!data) return null;

                      const [
                        termSuggestions,
                        productSuggestions,
                        pageSuggestions,
                        categorySuggestions,
                      ] = data;

                      const hasSuggestions =
                        termSuggestions?.length > 0 ||
                        categorySuggestions?.length > 0 ||
                        productSuggestions?.products?.length > 0 ||
                        pageSuggestions?.length > 0;

                      if (!hasSuggestions)
                        return (
                          <P className="pb-5 pl-3 text-sm text-neutral-2">
                            No results
                          </P>
                        );

                      return (
                        <>
                          <div className="flex px-1 pb-3 sm:flex-col lg:flex-row">
                            <div className="sm:w-full lg:w-1/4 ">
                              {termSuggestions &&
                                termSuggestions.length > 0 && (
                                  <div className="p-4">
                                    <SearchSuggestions
                                      title={"Suggestions"}
                                      suggestions={termSuggestions}
                                    />
                                  </div>
                                )}
                              {categorySuggestions &&
                                categorySuggestions.length > 0 && (
                                  <div className="p-4">
                                    <SearchSuggestions
                                      title={"Categories"}
                                      suggestions={categorySuggestions}
                                    />
                                  </div>
                                )}
                              {pageSuggestions &&
                                pageSuggestions.length > 0 && (
                                  <div className="p-4">
                                    <SearchSuggestions
                                      title={"Pages"}
                                      suggestions={pageSuggestions}
                                    />
                                  </div>
                                )}
                            </div>

                            <div className="bg-white p-4 sm:w-full lg:w-3/4">
                              <P
                                variant="muted"
                                className="text-sm font-semibold text-neutral-2"
                              >
                                Products
                              </P>
                              {productSuggestions &&
                                productSuggestions.totalProducts === 0 && (
                                  <P className="mt-2 text-neutral-2">
                                    No products found
                                  </P>
                                )}
                              <div>
                                <ProductImageSuggestions
                                  products={productSuggestions?.products}
                                />
                                {productSuggestions.totalProducts > 1 && (
                                  <NavigationMenuLink
                                    to={url(
                                      `/search?text=${encodeURIComponent(q)}`,
                                    )}
                                    prefetch="render"
                                    className="block pt-3 text-sm font-bold text-brand-primary-black underline md:text-base"
                                    id="view-all-results"
                                  >
                                    View All
                                  </NavigationMenuLink>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </Await>
                </Suspense>
              </div>
            );
          }}
        </SearchSuggestionsResource>
      )}
    </ClientOnly>
  );
};

export const SearchSuggestions = ({
  title,
  suggestions,
}: {
  title: string;
  suggestions:
    | AlgoliaSearchResultHit[]
    | { id: string; name: string; basePath: string }[]
    | { name: string; url: string }[];
}) => {
  const url = useURL();
  return (
    <div className="flex flex-col gap-3">
      {title && (
        <P variant="muted" className="text-sm font-semibold text-neutral-2">
          {title}
        </P>
      )}
      {suggestions?.slice(0, 3).map((suggestion, i) => {
        const query =
          "query" in suggestion ? suggestion.query : suggestion.name;
        //TODO: this will be changed once algolia is done for categories + pages
        const navigateUrl =
          "query" in suggestion
            ? `/search?text=${query}`
            : "id" in suggestion
              ? `/${suggestion.basePath}/c/${suggestion.id}`
              : "url" in suggestion
                ? `/pages/${suggestion.url}`
                : "";

        return (
          <NavigationMenuLink
            key={`suggestion-${query}-${i}`}
            to={url(navigateUrl)}
            prefetch="render"
            className="search-suggestion h-fit cursor-pointer text-sm text-brand-primary-black hover:underline md:text-base"
          >
            {query}
          </NavigationMenuLink>
        );
      })}
    </div>
  );
};
const ProductSuggestionLink = memo(
  ({
    product,
    children,
    ...props
  }: {
    product: AlgoliaSearchProductSuggestion;
    children: React.ReactNode;
  } & Omit<ComponentProps<typeof NavigationMenuLink>, "to">) => {
    const productUrl = useProductUrl(product);
    return (
      <NavigationMenuLink to={productUrl} {...props}>
        {children}
      </NavigationMenuLink>
    );
  },
);
ProductSuggestionLink.displayName = "ProductSuggestionLink";

export const ProductImageSuggestions = ({
  products,
}: {
  products: AlgoliaSearchProductSuggestion[];
}) => {
  const { isLoyaltyMember } = useRootLayoutData();

  return (
    <div className="flex flex-col gap-[18px] pt-[18px]">
      {products?.map(product => (
        <ProductSuggestionLink
          key={`product-suggestion-${product.code}`}
          product={product}
          prefetch="render"
          className="search-suggestion flex h-fit w-full cursor-pointer flex-row  text-sm font-light hover:no-underline"
        >
          {!product.image ? (
            <NoImageIcon />
          ) : (
            <img
              src={product.image ? getImageLink(product.image) : ""}
              alt={product?.name}
              className="h-[80px] w-[80px] rounded-xl object-cover"
            />
          )}
          <div className="px-2">
            <P className="text-sm font-medium text-brand-primary-black md:text-base">
              {product?.name}
            </P>
            <div
              className="min-h-4 my-1 "
              data-bv-show="inline_rating"
              data-bv-product-id={product.code}
            ></div>
            {IsGiftCard(product) && product.priceRangeMap ? (
              <PriceRangeDisplay priceRangeMap={product.priceRangeMap} />
            ) : (
              <PriceDisplay
                isMember={isLoyaltyMember}
                rrpPrice={product?.rrpPrice}
                memberPrice={getLowestPrice(product?.memberprice)}
                salePrice={
                  product?.onSale ? getLowestPrice(product?.price) : undefined
                }
                isSearchSuggestion={true}
              />
            )}
          </div>
        </ProductSuggestionLink>
      ))}
    </div>
  );
};
