import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import type { ArticleHeroBannerContent } from "../types";
import { ContentfulPicture } from "./picture";
import { RichTextRender } from "./rich-text";

export const ArticleHeroBanner = ({
  content,
  className = "",
  buttonClassname = "",
  textClassname = "",
  isBanner = false,
}: {
  content: ArticleHeroBannerContent;
  className?: string;
  buttonClassname?: string;
  textClassname?: string;
  isBanner?: boolean;
}) => {
  const fields = content.fields;
  const inspectorProps = useContentfulInspectorMode();

  if (!fields.backgroundImage) return null; // Ensure there's a background image to display

  const colourTheme = fields.colourTheme;

  return (
    <div className="bg-black">
      <div className="relative mx-auto flex h-full w-full max-w-1920 flex-col-reverse md:flex-row ">
        {/* Left Side (Text) - appears below on mobile */}
        <div className="flex w-full flex-col justify-center  p-8 md:w-1/2 xl:p-20 ">
          <div className="max-w-xl text-white">
            {/* Eyebrow Headline */}
            {fields.eyebrowHeadline && (
              <p className="mb-4  font-roboto text-2xl font-bold uppercase lg:text-[32px] xl:text-[52px]">
                {fields.eyebrowHeadline}
              </p>
            )}

            {/* Main Headline */}
            {fields.headline && (
              <h2 className="font-roboto text-2xl text-[32px] font-semibold uppercase italic leading-none lg:text-[56px]">
                {fields.headline}
              </h2>
            )}

            {/* Rich Text Body */}
            {fields.body && fields.body.fields.richText && (
              <div className="mt-2 text-base md:text-lg lg:text-xl">
                <RichTextRender
                  content={fields.body.fields.richText}
                  entryId={fields.body.sys.id}
                  textAlignment={fields.body.fields.textAlignment}
                  textClassname={`${textClassname} text-white font-roboto`}
                />
              </div>
            )}
          </div>
        </div>

        {/* Right Side (Image) - appears above on mobile */}
        <div
          className="w-full md:w-1/2"
          {...inspectorProps({
            entryId: content.sys.id,
            fieldId: "backgroundImage",
          })}
        >
          <ContentfulPicture
            image={fields.backgroundImage}
            className="h-full w-full object-cover"
            isBanner={isBanner}
          />
        </div>
      </div>
    </div>
  );
};
