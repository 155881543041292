import type { ChangeEvent } from "react";
import { useState } from "react";

import { type FetcherWithComponents, useNavigation } from "@remix-run/react";

import Breadcrumbs from "@commerce/components/breadcrumbs";
import { ChevronLeftIcon } from "@radix-ui/react-icons";

import { Form, FormFieldInput } from "~/components/forms/Form";
import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { Button } from "~/components/ui/button";
import { SmileyIcon } from "~/components/ui/icons/smiley";
import { Text } from "~/components/ui/text";
import { TriButton } from "~/components/ui/tri-button";
import { renderContentfulComponent } from "~/lib/generic-template-helper";
import { checkEmailValidator } from "~/routes/($locale)+/_auth+/login";

import { useRootLayoutData } from "../../_layout";

type Props = {
  fetcher: FetcherWithComponents<unknown>;
  actionData: unknown;
  isLoading: boolean;
  customerEmail: string;
  action: string;
  handleBackButton?: () => void;
  isFlyoutMode?: boolean;
};

const EmailCheckForm = ({
  action,
  fetcher,
  actionData,
  customerEmail,
  handleBackButton,
  isLoading,
  isFlyoutMode = false,
}: Props) => {
  const [isInputEmpty, setIsInputEmpty] = useState(!!customerEmail);
  const { configurationTemplate } = useRootLayoutData();
  const registrationContent =
    configurationTemplate?.registrationContentBlocks?.fields;
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsInputEmpty(!!event.target.value);
  };

  const navigation = useNavigation();
  const isNavigatingToRegister =
    navigation.location?.pathname.includes("/register") &&
    navigation.state === "loading";

  return (
    <>
      <div className="flex-1">
        {isFlyoutMode && handleBackButton && (
          <div className="flex cursor-pointer items-center font-bold underline">
            <Button
              size="1"
              variant="link"
              onClick={handleBackButton}
              className="p-0 font-bold"
            >
              <ChevronLeftIcon width="16px" height="16px" />
              <p>Back</p>
            </Button>
          </div>
        )}

        {!isFlyoutMode && !handleBackButton ? (
          <Breadcrumbs pageName={"Sign Up/In"} />
        ) : null}

        {isFlyoutMode && (
          <div className="w-full flex-1">{/*TODO: Add content here*/}</div>
        )}

        <SmileyIcon className="h-10 w-10 lg:h-16 lg:w-16" />

        <Text className="!mt-4 text-2xl font-bold">Hey there!</Text>
        <Text className="!mt-2">
          First up, lets grab your email address to check if you have an
          account. We’ll check if there is an existing account, or we can start
          a new one for you
        </Text>
        <GlobalErrors data={actionData} showFieldErrors />
        <Form
          validator={checkEmailValidator}
          className="!mt-3 space-y-4"
          action={action}
          method="post"
          fetcher={fetcher}
        >
          <FormFieldInput
            label="Email Address*"
            name="email"
            autoComplete="username"
            type="email"
            placeholder="name@email.com"
            defaultValue={customerEmail}
            onChange={handleInputChange}
            className="text-contrast-black mt-1 font-semibold"
          />
          <div
            className={`w-full flex-1 ${
              isFlyoutMode ? "block" : "block md:hidden lg:hidden"
            }`}
          >
            {registrationContent?.registrationBottom &&
              renderContentfulComponent(
                registrationContent?.registrationBottom,
              )}
          </div>

          <div className="sticky -bottom-4 bg-white py-6">
            <TriButton
              type="submit"
              disabled={
                !isInputEmpty ||
                isNavigatingToRegister ||
                (isLoading && fetcher.formAction?.includes("checkLoginId"))
              }
              isLoading={
                isNavigatingToRegister ||
                (isLoading && fetcher.formAction?.includes("checkLoginId"))
              }
              className="mt-3 h-[52px] w-full px-6 text-base font-extrabold"
            >
              Continue
            </TriButton>
          </div>
        </Form>
      </div>
      {!isFlyoutMode && (
        <div className="hidden w-full flex-1 px-12 md:block lg:block">
          {registrationContent?.registrationBottom &&
            renderContentfulComponent(registrationContent?.registrationBottom)}
        </div>
      )}
    </>
  );
};

export default EmailCheckForm;
