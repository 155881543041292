import { LeftSideContent } from "~/components/meganav/meganav";
import { NavigationMenuLink } from "~/components/ui/navigation-menu";
import { useURL } from "~/contexts";

import type { LinkGroupContent, NavSectionContent } from "../types";
import { LinkGroupRow } from "./link-group";

type Props = {
  content?: LinkGroupContent;
  navContent?: NavSectionContent;
};

export const DesktopNavBar = ({ content, navContent }: Props) => {
  const url = useURL();

  return (
    <div className="mx-auto hidden max-w-1920 bg-brand-primary-black text-white lg:flex lg:h-[50px] lg:items-center lg:px-8 xl:px-24">
      <LeftSideContent
        className="sm-max:ml-0   h-5 w-auto"
        navContent={navContent}
      />
      <NavigationMenuLink
        to={url("/product-list/shop/")}
        aria-label="Go to my account"
        prefetch="render"
        className="flex w-fit flex-row items-center font-extrabold no-underline hover:text-neutral-9 hover:no-underline lg:ml-8"
      >
        Browse Products
      </NavigationMenuLink>
      {content && (
        <LinkGroupRow
          content={content}
          linkStyles="font-extrabold no-underline hover:text-neutral-9 hover:no-underline"
          containerStyles="ml-[30px] flex items-center gap-[30px]"
        />
      )}
    </div>
  );
};
