import { Suspense } from "react";

import { Await, Link } from "@remix-run/react";

import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";

import type { CategoryHierarchy } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { getImageMediaLink } from "~/commerce-sap/shop/utils/image";
import { Accordion, AccordionItem } from "~/components/ui/accordion";
import { useURL } from "~/contexts";
import { useCategories, useCategory } from "~/contexts/mobile-menu";
import { cn } from "~/lib/ui";

export const MobileMenu = () => {
  const url = useURL();
  const {
    selectedCategory,
    setSelectedCategory,
    goBack,
    categoryHistory,
    categoryDepth,
  } = useCategory();
  const categories = useCategories();
  const historyMaxDepth = categoryDepth - 1;

  return (
    <div className="flex w-full flex-col justify-center gap-4 text-left">
      <Suspense fallback={<div>Loading...</div>}>
        <Await resolve={categories}>
          {categories => (
            <Accordion type="multiple">
              {!selectedCategory ? (
                categories?.subcategories?.map(
                  (category: CategoryHierarchy, index, array) => {
                    /* Hide categories that are marked to be hidden from the navigation bar */
                    if (category.hideFromNavigationBar === "Yes") return;

                    const isLast = index === array.length - 1;
                    return (
                      <AccordionItem
                        onClick={() => setSelectedCategory(category)}
                        key={category.id}
                        value={`${category.id}`}
                        className="cursor-pointer"
                      >
                        <div
                          className={cn(
                            "flex h-[60px] items-center justify-between border-b border-neutral-6 px-0 align-middle text-base font-bold text-black data-[state=open]:bg-white",
                            isLast && "border-b-0",
                          )}
                        >
                          {category?.thumbnail?.url && (
                            <img
                              src={getImageMediaLink(category.thumbnail, {
                                type: "thumb",
                                params: {
                                  fmt: "auto",
                                },
                              })}
                              alt="thumbnail"
                              className="mr-2 h-10 w-[50px]"
                            ></img>
                          )}
                          {category.name}{" "}
                          <ChevronRightIcon className="h-5 w-5" />
                        </div>
                      </AccordionItem>
                    );
                  },
                )
              ) : (
                <div className="mt-6 flex flex-col">
                  <button
                    onClick={goBack}
                    className="mb-4 flex items-center text-base font-bold"
                  >
                    <ChevronLeftIcon className="mr-2 h-5 w-5" />
                    {categoryHistory.length < 2
                      ? "Home"
                      : categoryHistory[categoryHistory.length - 2].name}
                  </button>
                  <Label className="mb-2 inline-block text-base font-semibold text-neutral-2">
                    {selectedCategory.name}
                  </Label>
                  {selectedCategory.contentPageId && (
                    <div className="flex h-[60px] items-center border-t border-neutral-6 align-middle text-base font-bold">
                      <Link
                        //TODO: PLP Content landing page
                        to={"/"}
                        className="w-full pl-4 hover:no-underline"
                        prefetch="render"
                      >
                        Explore {selectedCategory.name}
                      </Link>
                    </div>
                  )}

                  <div className="flex h-[60px] items-center border-t border-neutral-6 align-middle text-base font-bold">
                    <Link
                      to={
                        `${selectedCategory.url}?hideVehicleComponent=${
                          selectedCategory.hideVehicleComponent ?? "false"
                        }` || "/"
                      }
                      className="w-full pl-4 hover:no-underline"
                      prefetch="render"
                    >
                      Shop All {selectedCategory.name}
                    </Link>
                  </div>

                  {selectedCategory?.subcategories?.map(
                    (category: CategoryHierarchy, index, array) => {
                      if (category.hideFromNavigationBar === "Yes") return;
                      const isLast = index === array.length - 1;
                      return (
                        <AccordionItem
                          onClick={() => {
                            if (
                              !!category?.subcategories?.length &&
                              categoryHistory.length !== historyMaxDepth
                            ) {
                              setSelectedCategory(category);
                            }
                          }}
                          key={category.id}
                          value={`${category.id}`}
                          className="cursor-pointer"
                        >
                          <div
                            className={cn(
                              "flex h-[60px] items-center justify-between border-b border-neutral-6 px-0 align-middle text-base font-bold text-black data-[state=open]:bg-white",
                              isLast && "border-b-0",
                              index === 0 && "border-t",
                            )}
                          >
                            {!!category?.subcategories?.length &&
                            categoryHistory.length !== historyMaxDepth ? (
                              <>
                                <span className="pl-4">{category.name} </span>
                                <ChevronRightIcon className="h-5 w-5" />
                              </>
                            ) : (
                              <Link
                                to={url(
                                  `${category.url}?hideVehicleComponent=${
                                    category.hideVehicleComponent ?? "false"
                                  }` || "",
                                )}
                                className="flex h-full w-full items-center hover:no-underline"
                              >
                                <span className="pl-4">{category.name}</span>
                              </Link>
                            )}
                          </div>
                        </AccordionItem>
                      );
                    },
                  )}
                </div>
              )}
            </Accordion>
          )}
        </Await>
      </Suspense>
    </div>
  );
};
