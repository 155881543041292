import type { BapcorFitment } from "~/commerce-sap/.server/api/generated/__generated_apis";

export const productTileOnStockCount = 15;
export const normalizeArray = (arr: string[] = []): string[] => {
  return arr
    .map(value => value.trim())
    .filter(value => value !== "" && value !== ";");
};

export const filterUniqueFitmentData = (
  data: BapcorFitment[] | undefined | null,
): BapcorFitment[] => {
  if (!data) return [];

  const uniqueFitments: BapcorFitment[] = [];
  const seenFitments = new Set<string>();

  data.forEach(fitment => {
    const normalizedFitment = {
      subCatDescription: fitment.subCatDescription,
      footnote: normalizeArray(fitment.footnote || []),
      grade: normalizeArray(fitment.grade || []),
      pcq: fitment.pcq,
    };
    const fitmentKey = JSON.stringify(normalizedFitment);
    if (!seenFitments.has(fitmentKey)) {
      seenFitments.add(fitmentKey);
      uniqueFitments.push(fitment);
    }
  });

  return uniqueFitments;
};
