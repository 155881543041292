import { useEffect, useState } from "react";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

// Custom RichTextRender for styled Rich Text elements
// https://www.figma.com/design/IlUexxmZoOpQtC5SXwFpTw/Autobarn---Website-Components?node-id=433-34656&m=dev
import { cn } from "~/lib/ui";

import type { LayoutContainerContent } from "../types";
import { getMaxWidthClass } from "../util";
import { CardTextBox } from "./card-text-box";
import { HeroBannerAutobarn } from "./hero-banner-autobarn";
import { RichTextStyledRenderer } from "./rich-text-styled";
import { SideBySide } from "./side-by-side";
import ContentfulVideo from "./video";

// Helper function to render individual components
const getComponent = (content: any, key: number) => {
  switch (content.sys.contentType.sys.id) {
    case "video":
      return <ContentfulVideo key={key} content={content} />;
    case "cardTextBox":
      return <CardTextBox key={key} content={content} />;
    case "sideBySide":
      return <SideBySide key={key} content={content} />;
    case "componentHeroBanner":
      return (
        <HeroBannerAutobarn
          key={key}
          content={content}
          className="h-[576px] rounded-md md:h-auto md:min-h-[320px]"
        />
      );
    case "richText":
    case "richTextWithoutLinks":
    case "richTextBody": {
      // Handle rich text specifically
      const richTextContent = content.fields.richText || content.fields.body;

      if (!richTextContent) {
        console.warn("RichText content is undefined for entry:", content);
        return null;
      }

      return (
        <RichTextStyledRenderer
          key={key}
          content={richTextContent}
          entryId={content.sys.id}
          contentType={content.sys.contentType.sys.id}
        />
      );
    }
    case "unorderedList": {
      const unorderedListContent = content.fields.unorderedListItems;
      const bulletStyling = content.fields.bulletStyling;

      if (!unorderedListContent) {
        console.warn("Unordered list items are undefined for entry:", content);
        return null;
      }

      return (
        <RichTextStyledRenderer
          key={key}
          content={unorderedListContent}
          entryId={content.sys.id}
          contentType={content.sys.contentType.sys.id}
          bulletStyling={bulletStyling}
        />
      );
    }
    case "orderedList": {
      const orderedListContent = content.fields.orderedListItems;
      const markerStyling = content.fields.markerStyling;

      if (!orderedListContent) {
        console.warn("Ordered list items are undefined for entry:", content);
        return null;
      }

      return (
        <RichTextStyledRenderer
          key={key}
          content={orderedListContent}
          entryId={content.sys.id}
          contentType={content.sys.contentType.sys.id}
          markerStyling={markerStyling}
        />
      );
    }
    default:
      return null;
  }
};

export const LayoutContainer = ({
  content,
  className = "",
}: {
  content: LayoutContainerContent;
  className?: string;
}) => {
  const { fields } = content;

  const inspectorProps = useContentfulInspectorMode();

  // State for managing maximum width class
  const [maxWidthClass, setMaxWidthClass] = useState<string>(() =>
    getMaxWidthClass(fields.maxWidth),
  );

  // Set maxWidthClass dynamically if maxWidth changes
  useEffect(() => {
    setMaxWidthClass(getMaxWidthClass(fields.maxWidth));
  }, [fields.maxWidth]);

  return (
    <div
      className={`layout-container ${className} ${maxWidthClass} mx-auto  px-5 xl:px-0`}
      {...inspectorProps({ entryId: content.sys.id, fieldId: "component" })}
    >
      <div
        className={cn(
          "mx-auto flex w-full  gap-7 lg:gap-[40px]",
          "flex-col",
          className,
        )}
      >
        {/* Render each component dynamically */}
        {fields.component?.map((comp, key) => getComponent(comp, key))}
      </div>
    </div>
  );
};
