import { useState } from "react";

import { useFetcher } from "@remix-run/react";

import { isValidationError } from "~/components/forms/validationErrorResponse";
import { useOnActionCompleted } from "~/lib/remix/fetcher";
import { cn } from "~/lib/ui";
import EmailCheckForm from "~/routes/($locale)+/_auth+/components/email-check-form";
import ForgotPasswordForm from "~/routes/($locale)+/_auth+/components/forgot-password-form";
import ForgotPasswordSuccessForm from "~/routes/($locale)+/_auth+/components/forgot-password-success-form";
import LoginForm from "~/routes/($locale)+/_auth+/components/login-form";

import type { action } from "../login";
import { RegisterDrawer } from "./register-drawer";

type LoginStep =
  | "login"
  | "register"
  | "email-check"
  | "password-reset"
  | "password-reset-success";

export const AuthForm = () => {
  const fetcher = useFetcher<typeof action>({ key: "login-drawer" });
  const data = fetcher.data;
  const [loginStep, setLoginStep] = useState<LoginStep>("email-check");
  const customerEmail = isValidationError(data)
    ? data.repopulateFields.email
    : data && "customerEmail" in data
      ? data.customerEmail
      : "";
  const isLoading = fetcher.state !== "idle";
  useOnActionCompleted<{ customerEmail?: string; isLoginIdAvailable: boolean }>(
    "checkLoginId",
    data => {
      if (!data?.isLoginIdAvailable) {
        setLoginStep("login");
      }
      if (data?.isLoginIdAvailable) {
        setLoginStep("register");
      }
    },
  );

  useOnActionCompleted<{ forgotPasswordEmailSent?: boolean }>(
    "forgottenPasswordReset",
    data => {
      if (data?.forgotPasswordEmailSent) setLoginStep("password-reset-success");
    },
  );

  if (loginStep === "register") {
    return (
      <RegisterDrawer
        customerEmail={customerEmail}
        key={customerEmail}
        onBackButtonClick={() => setLoginStep("email-check")}
      />
    );
  }
  return (
    <div className={cn("flex h-full justify-center px-6 py-4")}>
      <div className={cn("flex  w-full flex-col space-y-8")}>
        {loginStep === "password-reset-success" && (
          <ForgotPasswordSuccessForm isFlyoutMode={true} />
        )}
        {loginStep === "password-reset" && (
          <ForgotPasswordForm
            action={"/reset-password/?action=forgottenPasswordReset"}
            fetcher={fetcher}
            actionData={data}
            isLoading={isLoading ?? false}
            customerEmail={customerEmail ?? ""}
            handleBackButton={() => setLoginStep("login")}
            isFlyoutMode={true}
          />
        )}
        {loginStep === "login" && (
          <LoginForm
            handleBackButton={() => setLoginStep("email-check")}
            action={"/login/?action=loginUser&noRedirect=true"}
            fetcher={fetcher}
            actionData={data}
            isLoading={isLoading ?? false}
            customerEmail={customerEmail ?? ""}
            isFlyoutMode={true}
            onForgotPasswordClick={() => setLoginStep("password-reset")}
          />
        )}
        {loginStep === "email-check" && (
          <EmailCheckForm
            fetcher={fetcher}
            actionData={data}
            isLoading={isLoading ?? false}
            customerEmail={customerEmail ?? ""}
            action={"/login?action=checkLoginId"}
            isFlyoutMode={true}
          />
        )}
      </div>
    </div>
  );
};
