import { useMemo } from "react";

import { Link } from "@remix-run/react";

import { getImageLink } from "@commerce/shop/utils/image";

import type { Image } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useURL } from "~/contexts";
import { useGTMTracker } from "~/google-tagmanager";
import { cn } from "~/lib/ui";

import { useProductTileData } from "../../hooks/use-product-tile-data";

type ProductTileImageProps = {
  product: {
    images?: Image[] | { format: string; url: string }[];
    code?: string;
    name?: string;
  };
  linkTo?: string;
  list_id: string;
  list_name: string;
  index: number;
  isCarousel?: boolean;
};

const ProductTileImage = ({
  product,
  linkTo,
  list_id,
  list_name,
  index = 0,
  isCarousel = false,
}: ProductTileImageProps) => {
  const { getHystoryState } = useProductTileData(product);
  const url = useURL();
  const { selectItemEvent } = useGTMTracker();

  const productImage = useMemo(() => {
    return Array.isArray(product.images)
      ? product.images?.find((image: Image) => image.format === "product")
      : undefined;
  }, [product.images]);

  return (
    <Link
      state={getHystoryState(productImage)}
      to={linkTo ?? url(`/${product.code}.html`)}
      className="sm:pb-[20px]"
      onClick={() => selectItemEvent(product, index, list_id, list_name)}
    >
      <div
        className={cn(
          "flex h-64 justify-center sm:h-[110px] sm:w-[110px]",
          isCarousel
            ? "carousel-product-tile-image mx-auto md:h-[auto] md:w-4/5"
            : "listing-product-tile-image md:h-[256px] md:w-full",
        )}
      >
        {productImage && (
          <img
            src={getImageLink(productImage.url ?? "")}
            alt={product.name}
            className="sm:[h-110px] md:h-full"
          />
        )}
      </div>
    </Link>
  );
};

export default ProductTileImage;
