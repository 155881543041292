import { useEffect, useMemo, useState } from "react";

import type { FetcherWithComponents } from "@remix-run/react";
import { Link, useFetcher } from "@remix-run/react";

import type { StoreFinderSearchPage } from "~/commerce-sap/.server/api/generated/__generated_apis";
import {
  Form,
  FormFieldCheckbox,
  FormFieldInput,
  FormFieldSelect,
} from "~/components/forms/Form";
import { GlobalErrors } from "~/components/forms/GlobalErrors";
import { SelectItem } from "~/components/ui/select";
import { Text } from "~/components/ui/text";
import { TriButton } from "~/components/ui/tri-button";
import { useURL } from "~/contexts";
import { AU_STATE_CODES } from "~/lib/constants";
import { renderContentfulComponent } from "~/lib/generic-template-helper";
import { useReCaptcha } from "~/recaptcha";
import FormDatePicker from "~/routes/($locale)+/_auth+/components/form-date-picker";
import FormPhoneInputField from "~/routes/($locale)+/_auth+/components/form-phone-input-field";
import { registerAlmostDoneValidator } from "~/routes/($locale)+/_auth+/register";

import { useRootLayoutData } from "../../_layout";
import { TERMS_AND_CONDITIONS_URL } from "../../account+/consts";

type Props = {
  fetcher: FetcherWithComponents<unknown>;
  actionData: unknown;
  isLoading: boolean;
  handleBackButton: () => void;
  isFlyoutMode?: boolean;
  regToken?: string;
  recaptchaSecret: string;
  requireCaptcha?: boolean;
  stores?: StoreFinderSearchPage;
};

export const StateOptions = AU_STATE_CODES.map(option => {
  return (
    <SelectItem key={option} value={option} className="h-[48px]">
      {option}
    </SelectItem>
  );
});

export const StoreSelector = ({
  stores,
  state,
  defaultStore,
}: {
  state: string | null;
  defaultStore?: string;
  stores: StoreFinderSearchPage;
}) => {
  const storeOptions = useMemo(() => {
    return (
      stores?.stores
        ?.filter(s =>
          state ? s.address?.region?.isocode === `AU-${state}` : true,
        )
        .sort((a, b) =>
          (a.displayName ?? "").localeCompare(b.displayName ?? ""),
        )
        .map(store => {
          return (
            <SelectItem
              key={store.name}
              value={store.name ?? ""}
              className="h-[48px]"
            >
              {store.displayName}
            </SelectItem>
          );
        }) ?? []
    );
  }, [state, stores?.stores]);

  return (
    <FormFieldSelect
      placeholder="Select Preferred Store"
      description="Check in-store product availability"
      name="preferredStore"
      label={"Preferred Store*"}
      options={storeOptions}
      defaultValue={defaultStore}
    />
  );
};

const RegisterAlmostDone = ({
  fetcher,
  stores,
  isLoading,
  actionData,
  requireCaptcha,
  regToken,
  recaptchaSecret,
  isFlyoutMode = false,
}: Props) => {
  const [captchaToken, setCaptchaToken] = useState("");
  const recaptchaStatus = useReCaptcha();
  const { configurationTemplate } = useRootLayoutData();
  const registrationContent =
    configurationTemplate?.registrationContentBlocks?.fields;
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const url = useURL();

  const fetcherVerify = useFetcher<{
    confirmPassword: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }>({ key: "register-verify" });

  useEffect(() => {
    if (
      "grecaptcha" in window &&
      requireCaptcha &&
      recaptchaStatus === "done"
    ) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.render("recaptcha-container", {
          sitekey: recaptchaSecret,
          callback: (token: string | null) => {
            setCaptchaToken(token ?? "");
          },
          "expired-callback": () => {
            setCaptchaToken("");
          },
          "error-callback": () => {
            console.error("reCAPTCHA error");
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaStatus]);

  const storeOptions =
    stores?.stores?.map(store => {
      return (
        <SelectItem
          key={store.name}
          value={store.displayName ?? ""}
          className="h-[48px]"
        >
          {store.displayName}
        </SelectItem>
      );
    }) ?? [];

  return (
    <div className="flex">
      <div className=" flex-1 pb-9">
        <div className="w-full flex-1 pt-6">
          {registrationContent?.registrationTop &&
            renderContentfulComponent(registrationContent.registrationTop)}
        </div>
        <Text className="!mt-4 text-2xl font-bold">Almost done</Text>
        <Text className="text-sm font-normal">
          Few more details needed copy to make your experience the best etc
        </Text>

        <GlobalErrors data={fetcher.data} showFieldErrors={false} />
        <Form
          validator={registerAlmostDoneValidator}
          className="!mt-3 space-y-4 text-sm"
          action={"/register/?action=registerUser"}
          method="post"
          fetcher={fetcher}
        >
          <FormDatePicker
            name="birthDay"
            description="Get a gift on your birthday"
          />

          <FormPhoneInputField
            label={"Mobile*"}
            name="phoneNumber"
            autoComplete="phone-number"
            type="tel"
            maxLength={10}
            description="Receive SMS updates and exclusive offers"
            placeholder="Please enter a valid Mobile Number"
            className="text-contrast-black mt-1 font-semibold"
          />

          <FormFieldSelect
            placeholder="Select State"
            name="state"
            description="Faster checkout process"
            label={"State*"}
            //There is no way with validation-form to trigger a re-render with the new value once selected
            //This is required in order to filter the stores based on the selected state
            onValueChange={value => setSelectedState(value)}
            options={StateOptions}
          />

          <StoreSelector
            key={selectedState || "store-selector"} // Trigger a re-render when the state changes
            stores={stores ?? {}}
            state={selectedState}
          />

          <FormFieldCheckbox
            name="termsAndConditionsAgreement"
            label={
              <div>
                <Text>
                  I am confirming that I have read and agreed with the autObarn
                </Text>
                <Link
                  to={url(TERMS_AND_CONDITIONS_URL)}
                  className={"font-bold underline"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </Link>
              </div>
            }
          />

          <div id="recaptcha-container" />

          <FormFieldInput
            name="captchaToken"
            type="hidden"
            value={captchaToken || ""}
          />
          <FormFieldInput
            name="regToken"
            type="hidden"
            value={regToken || ""}
          />
          <input
            type="hidden"
            name="captchaRequired"
            value={requireCaptcha + ""}
          />
          {Object.keys(fetcherVerify.data ?? {}).map(key => {
            return (
              <input
                type="hidden"
                key={key}
                name={key}
                value={
                  fetcherVerify.data
                    ? fetcherVerify.data[key as keyof typeof fetcherVerify.data]
                    : ""
                }
              />
            );
          })}

          <div className="sticky -bottom-4  z-20  mt-8 bg-white p-6">
            <TriButton
              type="submit"
              disabled={isLoading && fetcher.formAction?.includes("register/")}
              isLoading={isLoading && fetcher.formAction?.includes("register/")}
              className="mt-3 h-[52px] w-full px-6  text-base font-extrabold"
            >
              Create Account
            </TriButton>
          </div>
        </Form>
      </div>
      {!isFlyoutMode && (
        <div className="hidden w-full flex-1 px-12 md:block lg:block">
          {registrationContent?.registrationBottom &&
            renderContentfulComponent(registrationContent.registrationBottom)}
        </div>
      )}
    </div>
  );
};

export default RegisterAlmostDone;
